import React from "react";

const Question = ({ question, onSelectOption }) => {
  return (
    <div className="p-4 border rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">{question.question}</h2>
      <div className="space-y-2">
        {question.options.map((option, index) => (
          <button
            key={index}
            onClick={() => onSelectOption(option.nextQuestionId, option.tasks)}
            className="block w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            {option.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Question;
