import React from "react";

const CompletionModal = ({ onRestart }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Checklist Completed!</h2>
        <p className="mb-4">You have completed the checklist.</p>
        <button
          onClick={onRestart}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Restart Checklist
        </button>
      </div>
    </div>
  );
};

export default CompletionModal;
