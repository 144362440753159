import React, { useState } from "react";

const Checklist = ({ items, onComplete }) => {
  console.log(items);
  const [checkedItems, setCheckedItems] = useState(items);

  const handleItemClick = (index) => {
    const newItems = [...checkedItems];
    newItems[index].checked = !newItems[index].checked;
    setCheckedItems(newItems);

    if (newItems.every((item) => item.checked)) {
      onComplete();
    }
  };

  return (
    <div className="p-2 bg-white rounded-xl shadow-md space-y-1">
      {checkedItems.map((item, index) => (
        <div
          key={index}
          className={`flex items-center p-2 border rounded ${
            item.checked ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => handleItemClick(index)}
        >
          <input
            type="checkbox"
            checked={item.checked}
            readOnly
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span
            className={`ml-4 text-lg ${
              item.checked ? "line-through text-gray-500" : ""
            }`}
          >
            {item.text}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Checklist;
