import React, { useState } from "react";
import questionsData from "./questions.json";
import Question from "./Question";
import Checklist from "./Checklist";
import CompletionModal from "./CompletionModal";

// App Component
const App = () => {
  const [questions] = useState(questionsData);
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const currentQuestion = questions.find((q) => q.id === currentQuestionId);

  const handleSelectOption = (nextQuestionId, newTasks) => {
    setChecklistItems([
      ...checklistItems,
      newTasks.map((task) => ({ text: task, checked: false })),
    ]);
    if (nextQuestionId) {
      setCurrentQuestionId(nextQuestionId);
    } else {
      setQuizCompleted(true);
    }
  };

  const handleCompleteChecklist = () => {
    setShowModal(true);
  };

  const handleResetChecklist = () => {
    setChecklistItems([]);
    setQuizCompleted(false);
    setCurrentQuestionId(1);
    setShowModal(false);
  };

  return (
    <div className="min-h-svh flex items-center justify-center bg-gray-100">
      <div className="p-4 max-w-md w-full bg-white rounded-xl shadow-md">
        {!quizCompleted ? (
          currentQuestion ? (
            <Question
              question={currentQuestion}
              onSelectOption={handleSelectOption}
            />
          ) : (
            <p className="text-lg">Loading...</p>
          )
        ) : !showModal ? (
          <Checklist
            items={checklistItems.flat()}
            onComplete={handleCompleteChecklist}
          />
        ) : (
          <CompletionModal onRestart={handleResetChecklist} />
        )}
      </div>
    </div>
  );
};

export default App;
